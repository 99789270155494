import * as React from 'react';
import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

import {
  AuthorsJsonConnection,
  CoursesJsonConnection,
  BundlesJsonConnection,
  TestimonialsJsonConnection
} from '../domain/graphql-types';

import {
  authorFromAuthorsJsonEdge,
  courseFromCoursesJsonEdge,
  testimonialFromTestimonialJsonEdge,
  trackFromTracksJsonEdge
} from '../domain/converters';

import Hero from '../components/home/Hero/Hero';
import { bundleFromBundlesJsonEdge } from '../domain/converters/bundle-types';

import BundleSection from '../components/home/BundleSection/BundleSection';
import CoursesSection from '../components/home/CoursesSection/CoursesSection';
import { CourseFilterType } from '../components/home/CourseFilter/CourseFilter';
import SubHeroSection from '../components/home/SubHeroSection/SubHeroSection';
import Benefits from '../components/home/Benefits/Benefits';
import Logos from '../components/home/Logos/Logos';

import SignUpSection from '../components/shared/SignUpSection/SignUpSection';
import AddThisBlock from '../components/shared/AddThisBlock/AddThisBlock';
import { scrollToElementById } from '../utils/scrolling';
import Testimonials from '../components/Testimonials/Testimonials';

import TrainingLink from '../components/home/TrainingLink/TrainingLink';
import { MainLayout } from '../layouts/MainLayout';
import { Course } from '../domain/models';
import { Seo } from '../components/shared/Seo/Seo';
import { TrackSection } from '../components/home/TrackSection/TrackSection';
import { BrandsSection } from '../components/shared/Brands/BrandsSection';
import { TracksJsonConnection } from '../domain/graphql-types.d_old';
import SubHeroSection2 from '../components/home/SubHeroSection/SubHeroSection2';
import { NServices } from '../ncomponents/shared/services/services';
import { NSiteCTA } from '../ncomponents/shared/sitecta/sitecta';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    authorsConnection: AuthorsJsonConnection;
    coursesConnection: CoursesJsonConnection;
    bundlesConnection: BundlesJsonConnection;
    tracksConnection: TracksJsonConnection;
    testimonialsConnection: TestimonialsJsonConnection;
  };
}

interface IndexPageState {
  courses: Course[];
  selectedFilterType: CourseFilterType;
}

export default class extends React.Component<IndexPageProps, IndexPageState> {
  constructor(props: IndexPageProps) {
    super(props);

    const authors = this.props.data.authorsConnection.edges.map(
      authorFromAuthorsJsonEdge
    );

    const courses = this.props.data.coursesConnection.edges.map(c =>
      courseFromCoursesJsonEdge(c, authors)
    );

    this.state = {
      courses: courses,
      selectedFilterType: 'All'
    };
  }

  public componentDidMount() {
    //this.mountAddThis();

    //this.mountWebflowJs();
  }

  private mountAddThis() {
    const script = document.createElement("script");
    script.src =
      `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-597d29b3b4e298a5`;
    script.async = true;
    document.body.appendChild(script);
  }

  private mountWebflowJs() {
    const scriptJq = document.createElement("script");
    scriptJq.src = `https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js`;
    //scriptJq.integrity = 'sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=';


    const script = document.createElement("script");
    script.src = `js/webflow.js`;




    document.body.appendChild(scriptJq);
    document.body.appendChild(script);
  }

  /*
  private freeCoursesSelected() {
    this.setState({ selectedFilterType: 'Free' }, () => {
      this.scrollToElementById('courses');
    });
  }

  private premiumCoursesSelected() {
    this.setState({ selectedFilterType: 'All' }, () => {
      this.scrollToElementById('courses');
    });
  }

    private allBundlesSelected() {
    this.scrollToElementById('bundles');
  }
  */

  private allCoursesSelected() {
    this.scrollToElementById('courses');
  }



  private scrollToElementById(id: string) {
    scrollToElementById(id);
  }

  private filterByFilterType(filterType: CourseFilterType) {
    this.setState({ selectedFilterType: filterType });
  }

  private getFilteredCourses(filterType: CourseFilterType): Course[] {
    switch (filterType) {
      case 'All':
        return this.state.courses;
      case 'Free':
        return this.state.courses.filter(c =>
          c.products.some(p => p.pricereg === 0)
        );
      case 'Core':
        return this.state.courses.filter(c => c.flavors.includes('Core'));
      case 'Angular':
        return this.state.courses.filter(c => c.flavors.includes('Angular'));
      case 'Vue':
        return this.state.courses.filter(c => c.flavors.includes('Vue'));
      default:
        return this.state.courses;
    }

    /*
        if (this.state.selectedFlavor === undefined) {
            return this.state.courses;
        } else {
            return this.state.courses.filter(c => c.flavors.includes(this.state.selectedFlavor));
        }
        */
  }

  public render() {
    const filteredCourses = this.getFilteredCourses(
      this.state.selectedFilterType
    );

    const bundles = this.props.data.bundlesConnection.edges.map(b =>
      bundleFromBundlesJsonEdge(b, this.state.courses)
    );

    const tracks = this.props.data.tracksConnection.edges.map(t => trackFromTracksJsonEdge(t));

    const testimonials = this.props.data.testimonialsConnection.edges.map(
      testimonialFromTestimonialJsonEdge
    );

    const pageTitle = `Nuvious Home`;

    return (
      <MainLayout>
        <Seo />
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <div className="section cc-store-home-wrap">
          <div className="intro-header">
            <div className="intro-content cc-homepage">
              <div className="intro-text">
                <div className="heading-jumbo">NativeScript Experts</div>
                <div className="paragraph-bigger cc-bigger-white-light">Your mobile app delivered<br /></div>
              </div>
              <a href="about.html" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
                <div>Learn More</div>
              </a>
            </div>
          </div>
          <div className="container">
            <div className="motto-wrap">
              <div className="label cc-light">What we believe in</div>
              <div className="heading-jumbo-small">Custom software to power your business<br /></div>
            </div>
            <div className="divider" />
            <div className="home-content-wrap">
              <div className="w-layout-grid about-grid">
                <div id="w-node-76c147234d34-8b1fb63a">
                  <div className="home-section-wrap">
                    <div className="label cc-light">About</div>
                    <h2 className="section-heading">Who we are</h2>
                    <p className="paragraph-light">Nuvious is the result of a sound decision to take a new direction in software design. The idea was to shape and execute brilliantly-designed programs without sacrificing a sense of esthetic and usability. In short, we believe in the marriage of form and function to create products and services that we are proud to offer to our clients.</p>
                  </div>
                  <a href="about.html" className="button w-inline-block">
                    <div>Learn More</div>
                  </a>
                </div><img src="images/placeholder-3.svg" id="w-node-76c147234d3f-8b1fb63a" alt="" /></div>
              <div className="w-layout-grid about-grid cc-about-2">
                <div id="w-node-76c147234d41-8b1fb63a">
                  <div className="home-section-wrap">
                    <div className="label cc-light">Team</div>
                    <h2 className="section-heading">What we do</h2>
                    <p className="paragraph-light">We have been building and delivering custom software solutions for over ten years to businesses across a range of sectors, offering mobile and web application development and training.</p>
                  </div>
                  <a href="services.html" className="button w-inline-block">
                    <div>Learn More</div>
                  </a>
                </div><img src="images/placeholder-1.svg" id="w-node-76c147234d4c-8b1fb63a" alt="" /></div>
            </div>
          </div>
        </div>


        <NServices />
        <NSiteCTA />

      </MainLayout>
    );
  }
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    #get authors
    authorsConnection: allAuthorsJson(filter: { types: { in: "course" } }) {
      totalCount
      edges {
        node {
          id
          title
          name
          picture
          bio
          biolong
          twitter
          github
        }
      }
    }

    #get courses
    coursesConnection: allCoursesJson(sort: { order: ASC, fields: [order] }) {
      totalCount
      edges {
        node {
          id
          title
          flavors
          url
          label
          authors
          level
          order
          products {
            id
            name
            description
            licensesMin
            licensesMax
            pricereg
            pricesale
          }
        }
      }
    }

    #get bundles
    bundlesConnection: allBundlesJson {
      edges {
        node {
          id
          title
          subtitle
          description
          url
          promototal
          promoremaining
          courseIds
          bundleLevel
          products {
            id
            name
            description
            pricesale
            pricereg
            licensesMin
            licensesMax
          }
        }
      }
    }

    #get tracks
    tracksConnection: allTracksJson {
      totalCount
      edges {
        node {
          id
          title
          description
          bundles {
            id
            order
            description
          }
          levels {
            levelId
            title
            description
          }
        }
      }
    }

    #get testimonials
    testimonialsConnection: allTestimonialsJson(filter: {order: {lte: 6000}}) {
      totalCount
      edges {
        node {
          id
          name
          img
          twitter
          order
          quoteHtml
          titleHtml
        }
      }
    }
  }
`;
